import React from "react";
import {
    Title,
    Spacer,
    Text,
    List,
    Subtitle,
    SmallText,
    Image,
} from "../dynamic.components";
import ccd_sensor_image from "src/assets/images/snow-mode/ccd-sensor.png";
import light_image from "src/assets/images/snow-mode/light.png";
import { useStore } from "src/store";

export const QualityCore: React.FC = (): JSX.Element => {
    const { text } = useStore().activeSubLink;
    return (
        <>
            <Title title={text} />
            <div className="c-sidebar__body">
                <Subtitle subtitle="Optics and CCD sensor" />
                <Text text="ImageQuant™ 800 systems are fitted with Fujifilm F 0.74 large aperture lens with improved transmission combined and equipped with an 8.3 megapixel CCD chip that is ideal for imaging applications with long exposure times, such as chemiluminescence or weak signals from fluorescence." />
                <Spacer />
                <Subtitle subtitle="Sensitivity is a key feature for any imaging instrument" />
                <Text
                    text="It is related to the amount of photons that pass through the lens and reach the sensor. 
          ImageQuant™ 800 imaging systems are designed to avoid unnecessary optical interfaces which may prevent 
          photons from reaching the sensor. For this reason the ImageQuant™ 800 imaging system is deigned with a:"
                />
                <Spacer />
                <List
                    listArray={[
                        "Sample tray directly facing the lens and the sensor (this is the reason for its shape)",
                        "Fixed lens: zoom lenses adjust for optical interfaces, which may turn into reducing sensitivity ",
                        "Fujifilm lens with F 0.74* calculated for infinite distance is manufactured with a glass material which allows two times higher transmittance in the chemiluminescent wavelength compared to previous ImageQuantTM systems",
                    ]}
                />
                <Spacer x={2} />
                <SmallText text="*the smaller the F, the wider the aperture, letting in more light through the lens" />
                <Image
                    images={[
                        {
                            src: ccd_sensor_image,
                            alt: "CCD sensor image",
                        },
                        {
                            src: light_image,
                            alt: "Screen light image",
                            customHeight: "20%",
                        },
                    ]}
                />
            </div>
        </>
    );
};
