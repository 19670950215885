import React from "react";
import {
  Title,
  Spacer,
  Text,
  List,
  Subtitle,
  CustomLink,
  SmallText,
  Image,
} from "../dynamic.components";
import wave_length_image from "src/assets/images/many-imaging/wave-length.png";
import rgb_color_image from "src/assets/images/many-imaging/rgb-colors.png";
import fig_4_B_image from "src/assets/images/many-imaging/slide-11/Fig-4.-B.png";
import uv_cy5_image from "src/assets/images/many-imaging/slide-11/FIg-7.png";
import picture1_image from "src/assets/images/many-imaging/slide-12/picture1.png";
import wb1_cy5_Ch_Markerimage_image from "src/assets/images/many-imaging/slide-13/wb1-cy5_Ch-Marker.png";
import quick_stain_image from "src/assets/images/many-imaging/slide-13/Picture1.png";
import chemiluminescent_image from "src/assets/images/many-imaging/slide-13/Picture2.png";
import Chemi_MWM_color_image from "src/assets/images/many-imaging/slide-13/Chemi-MWM-color.png";
import MWM_chemi_real_color_image from "src/assets/images/many-imaging/slide-13/MWM-and-chemi-real-color.png";
import petri_definida_image from "src/assets/images/many-imaging/slide-14/petri_definida.jpg";
import elisa_OD_color_image from "src/assets/images/many-imaging/slide-14/ELISA_OD_color.png";
import clonogenic_assay_image from "src/assets/images/many-imaging/slide-14/clonogenic_assay.jpg";
import bacillus_colonies_image from "src/assets/images/many-imaging/slide-14/bacillus_colonies.png";
import lateral_flow_Streambio_image from "src/assets/images/many-imaging/slide-14/Lateral_flow_Streambio.png";
import epi_uv_image from "src/assets/images/many-imaging/slide-14/epi_uv.png";
import dibe_iq800_image from "src/assets/images/many-imaging/slide-14/DIBE_IQ800.png";
import parallax_effect_image from "src/assets/images/many-imaging/slide-14/parallax_effect.png";

export const ManyImagingApplications: React.FC = (): JSX.Element => {
  return (
    <>
      <Title title="Many imaging applications" />
      <div className="c-sidebar__body">
        <Subtitle subtitle="A wide variety of applications" />
        <Text text="The ImageQuant™ 800 system is equipped with six different types of light-emitting diodes (LEDs) and corresponding filters, making imaging easy for a wide variety of applications beyond chemiluminescence" />
        <Spacer />
        <Image
          images={[
            {
              src: wave_length_image,
              alt: "Wave length image",
              customWidth: "80%",
            },
          ]}
        />
        <Image
          images={[
            {
              src: rgb_color_image,
              alt: "Wave length image",
              customWidth: "80%",
            },
          ]}
        />
        <Subtitle subtitle="IR short and IR long illumination capabilities" />
        <Text
          text="Three-color overlay image of Western blot nitrocellulose membrane.
          Target ERK proteins were detected using an IR long (red) LED-filter combination and GAPDH detection used an IR short channel (green). Lysates were pre-labeled with Cy™3 (blue)."
        />
        <Spacer />
        <Text text="Sample: CHO cell lysate in the range 16 to 24 µg was loaded in each lane" />
        <Text text="Membrane: nitrocellulose from Cytiva" />
        <Spacer />
        <Image
          images={[
            {
              src: fig_4_B_image,
              alt: "Fig 4 B image",
            },
          ]}
        />
        <Spacer />
        <Text text="Parts of the flower were seen to fluoresce under different excitation wavelengths." />
        <Spacer />
        <Text
          text="In these images, we see the intricate vein-like pattern on the tips of the sepals fluoresce under 635 nm excitation 
          (red) wavelength. The lips of the flower fluoresce strongly under the 535 nm wavelength (green)."
        />
        <Image
          images={[
            {
              src: uv_cy5_image,
              alt: "Fig 4 B image",
              customWidth: "55%",
            },
          ]}
        />
        <Spacer />
        <Text text="Different imaging modes are available for colony imaging" />
        <List
          listArray={[
            "Uniform trans-white illumination to get optical density measurements in a single click ",
            "Color images (middle) to do epi-fluorescence imaging",
            "Total protein normalization with target protein and molecular weight marker",
            "Straightforward versatility, allowing users to image a wide variety of format samples which may require high resolution",
            "Coomassie™ gels to evaluate purity after chromatography with the densitometry mode and ImageQuant™ TL analysis software it is possible to quickly perform quantitative measurements and decide if the purification was satisfactory or if further optimization is needed (as shown below).",
          ]}
        />
        <Image
          images={[
            {
              src: picture1_image,
              alt: "Fig 4 B image",
              customWidth: "70%",
            },
          ]}
        />
        <Subtitle subtitle="Multiplexing images" />
        <Image
          images={[
            {
              src: wb1_cy5_Ch_Markerimage_image,
              alt: "wb1_cy5_Ch_Markerimage image",
              title: "Color for MWM (.TIF)",
              customWidth: "250px",
            },
            {
              src: quick_stain_image,
              alt: "Quick stain image",
              title: "QuickStain Cy™5 dye total protein staining (.TIF)",
              customWidth: "250px",
            },
            {
              src: chemiluminescent_image,
              alt: "Chemiluminescent image",
              title: "Chemiluminescent target protein (.TIF)",
              customWidth: "250px",
            },
          ]}
        />
        <Text text="Colorimetric OD captures in several sample formats different from membranes or gels (.JPG)" />
        <Image
          images={[
            {
              src: elisa_OD_color_image,
              alt: "Screen light image",
              customWidth: "20%",
            },

            {
              src: bacillus_colonies_image,
              alt: "bacillus_colonies image",
              customWidth: "20%",
            },
          ]}
        />
        <Text text="Epi UV fluorescence for lateral flow assays and bacterial  colonies (.TIF)" />
        <Image
          images={[
            {
              src: lateral_flow_Streambio_image,
              alt: "lateral_flow_Streambio image",
              customWidth: "20%",
            },
            {
              src: epi_uv_image,
              alt: "epi_uv image",
              customWidth: "20%",
            },
            {
              src: dibe_iq800_image,
              alt: "dibe_iq800 image",
              customWidth: "20%",
            },
            {
              src: parallax_effect_image,
              alt: "parallax_effect image",
              customWidth: "20%",
            },
          ]}
        />
      </div>
    </>
  );
};
