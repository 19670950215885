import React from "react";
import { cameraMoveTiming, resetCameraAnnotation } from "src/constants";
import {
    changeTexture,
    hideFootprint,
    showFootprint,
    toggleInsideBeam,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "src/store";
import { SubLink } from "src/types";
import { SidebarVisibility } from "src/types/index.d";

type SubLinkProps = {
    subLink: SubLink;
};

const SubLinkComponent: React.FC<SubLinkProps> = ({ subLink }) => {
    const { setActiveSubLink, setSidebarStatus, footprintVisible } = useStore();
    const openSidebarDelay = subLink.annotations ? cameraMoveTiming * 1000 : 0;

    const onSubLinkClick = () => {
        setActiveSubLink(subLink);
        if (subLink.annotations) {
            playHotspot(subLink.annotations[0]);
            // Play second annotation if there is not animation
            if (subLink.annotations[1] && !subLink.animations) {
                const secondAnnotation = subLink.annotations[1];
                setTimeout(() => {
                    playHotspot(secondAnnotation);
                }, openSidebarDelay);
            }
            if (subLink.id === "2-2") {
                showFootprint();
            }
            if (subLink.id === "1-1") {
                toggleInsideBeam("hide");
            }
            if (subLink.id === "1-2") {
                changeTexture({ active: "SCREEN_2", disactive: "SCREEN_1" });
            }
        }
        if (subLink.id !== "2-2" && footprintVisible) {
            hideFootprint();
            if (!subLink.annotations || subLink.annotations.length === 0) {
                playHotspot(resetCameraAnnotation);
            }
        }
        setTimeout(() => {
            if (subLink.id !== "2-2") {
                setSidebarStatus(SidebarVisibility.show);
            }
        }, openSidebarDelay);
    };

    return (
        <li className="c-cytiva__sub-list__item">
            <button
                className="button--fixwidth button button--primary button--large button--inline"
                onClick={onSubLinkClick}
            >
                {subLink.text}
            </button>
        </li>
    );
};

export default SubLinkComponent;
