import React from 'react';
import { useStore } from 'src/store';
import NavLink from './NavLink.component';

type NavLinkListProps = {

}

const NavLinkList: React.FC<NavLinkListProps> = () => {
  const { links } = useStore();
  return (
    <nav className='c-cytiva__nav'>
      <ul className='c-cytiva__nav-list'>
        {links.map(link => (
          <React.Fragment key={link.title}>
            <NavLink link={link} />
          </React.Fragment>
        ))}
      </ul>
    </nav>
  )
}

export default NavLinkList;