export const MODEL_UID = process.env.REACT_APP_MODEL_UID;

export const cameraMoveTiming = 3.2;
// Offset time between animation and sidebar opn/close
export const offsetTime = 600;

export const resetCameraAnnotation = "ResetCamera";

export const greenEmission = [0, 0.24620132669999983, 0.15896083509999984];
export const grayEmission = [0.1946178304, 0.1946178304, 0.1946178304];

export const filterSwitchingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690cad6f8d8d6d06000002_streamio-hq-480p.mp4?r=1651052296";
export const liquidManagementVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690cc36f8d8d354800000a_streamio-hq-480p.mp4?r=1651052308";
export const cellBleedingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d476f8d8d6d0600000a_streamio-hq-480p.mp4?r=1651052385";
export const cellHarvestingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_627246c96f8d8dbc6c000006_streamio-hq-480p.mp4?r=1651656397";
export const seamlessConnectionsVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_625fd7136f8d8da5f1000007_streamio-hq-480p.mp4?r=1650448337";
export const quickSingleUseVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d016f8d8d4f8a000002_streamio-hq-480p.mp4?r=1651052347";
export const singleUserInterfaceVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d076f8d8d57ae00000a_streamio-hq-480p.mp4?r=1651052359";
export const precisePerfusionVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d2e6f8d8d85b500000c_streamio-hq-480p.mp4?r=1651052374";
