import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";
import { QualityCore } from "./contents/image-quality/Quality-core";
import { HowSnowWorks } from "./contents/image-quality/How-snow-works";
import { ManyImagingApplications } from "./contents/flexibility/Many-imaging-applications";
import { SmallFootprint } from "./contents/flexibility/Small-footprint";
import { MiniPC } from "./contents/flexibility/MiniPC";
import { MultipleTrays } from "./contents/flexibility/Multiple-trays";
import { FilterDoor } from "./contents/flexibility/Filter-door";
import { SupportingRegulations } from "./contents/data-analysis/Supporting-regulations";
import { ImageQuant } from "./contents/data-analysis/ImageQuant";

const Content: FC = (): JSX.Element => {
    const { activeSubLink } = useStore();
    switch (activeSubLink.id) {
        case SubLinkId.QualityCore:
            return <QualityCore />;
        case SubLinkId.howSnowWorks:
            return <HowSnowWorks />;
        case SubLinkId.manyImagingApplication:
            return <ManyImagingApplications />;
        case SubLinkId.smallFootprint:
            return <SmallFootprint />;
        case SubLinkId.miniPc:
            return <MiniPC />;
        case SubLinkId.multipleTrays:
            return <MultipleTrays />;
        case SubLinkId.filterDoor:
            return <FilterDoor />;
        case SubLinkId.supprotRegulation:
            return <SupportingRegulations />;
        case SubLinkId.imageQuant:
            return <ImageQuant />;
        default:
            return <></>;
    }
};

const SidebarContent: FC = (): JSX.Element => {
    return (
        <div className="c-sidebar__wrapper">
            <Content />
        </div>
    );
};

export default SidebarContent;
