import React from "react";
import { Title, Spacer, Text, Image, List } from "../dynamic.components";
import detect_colonies_image from "src/assets/images/data-analysis/detect_colonies.png";
import picture2_image from "src/assets/images/data-analysis/Picture2.png";
import dendogram_image from "src/assets/images/data-analysis/Dendogram.png";
import graph_linked_to_dendogram_image from "src/assets/images/data-analysis/graph_linked_to_dendogram_1.png";
import view_DIBE_in_IQTL_image from "src/assets/images/data-analysis/3D_view_DIBE_in_IQTL.png";
import sds_page_image from "src/assets/images/data-analysis/SDS-PAGE.png";

export const ImageQuant: React.FC = (): JSX.Element => {
    return (
        <>
            <Title title="ImageQuant&trade; TL" />
            <div className="c-sidebar__body">
                <Spacer />
                <Text
                    text="Analyze your images with ImageQuant™ TL (IQTL), an advanced analysis software designed specifically for images of 1D gels, Western blots,
          multi-well plates, and colony plates."
                />
                <Spacer />
                <List
                    listArray={[
                        "Compare samples with similarity score and dendrograms for more accurate statistical analysis.",
                        "Use multi-channel normalization to adjust for differences in well loading conditions, correcting for pipetting errors.",
                        "Import Chromatogram curves from ÄKTA™ systems to connect peaks in chromatograms to samples run on analyzed SDS-PAGE gels.",
                    ]}
                />
                <Image
                    images={[
                        {
                            src: sds_page_image,
                            alt: "Detect colonies image",
                            customWidth: "60%",
                        },
                    ]}
                />
                <List
                    listArray={[
                        "Zoom-In and Export 3D view images to reveal hidden details in images.",
                        "GxP version for regulated environments that require FDA 21 CFR Part 11 compliance with data integrity and traceability features, including electronic signatures.",
                    ]}
                />
                <Image
                    images={[
                        {
                            src: detect_colonies_image,
                            alt: "Detect colonies image",
                        },
                        {
                            src: picture2_image,
                            alt: "Picture2 image",
                        },
                        {
                            src: dendogram_image,
                            alt: "dendogram image",
                        },
                        {
                            src: graph_linked_to_dendogram_image,
                            alt: "graph linked to dendogram image",
                        },
                    ]}
                />
                <Text text="Each colored rectangle corresponds to the colored ovals in the scatter plot graphs (*). The lanes are grouped together and circled, where each colored oval corresponds to the colored rectangles in the dendrograms" />
                <Spacer />
                <Image
                    images={[
                        {
                            src: view_DIBE_in_IQTL_image,
                            alt: "3D view DIBE in IQTL image",
                        },
                    ]}
                />
            </div>
        </>
    );
};
