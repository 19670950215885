import { CustomLink } from "../types";

export enum SubLinkId {
    QualityCore = "1-1",
    howSnowWorks = "1-2",
    manyImagingApplication = "2-1",
    smallFootprint = "2-2",
    miniPc = "2-3",
    multipleTrays = "2-4",
    filterDoor = "2-5",
    supprotRegulation = "3-1",
    imageQuant = "3-2",
}
export const links: CustomLink[] = [
    {
        id: "1",
        title: "Image quality",
        hasSublinks: true,
        subLinks: [
            {
                id: "1-1",
                text: "Quality core",
                annotations: ["small_footprint"],
                changeMaterial: [
                    "GR_1",
                    "GR_2",
                    "SCREEN_1",
                    "SCREEN_2",
                    "USB_PowCabl_HDMI",
                    "DECALS",
                    "LABELS",
                    "inside_beam",
                ],
            },
            {
                id: "1-2",
                text: "How SNOW works",
                annotations: ["screen"],
            },
        ],
    },
    {
        id: "2",
        title: "Flexibility",
        hasSublinks: true,
        subLinks: [
            {
                id: "2-1",
                text: "Many imaging applications",
            },
            {
                id: "2-2",
                text: "Small footprint",
                annotations: ["small_footprint"],
                changeMaterial: ["FOOTPRINT"],
            },
            {
                id: "2-3",
                text: "Mini PC",
                annotations: ["mini-PC_2", "mini-PC_1"],
            },
            {
                id: "2-4",
                text: "Multiple trays",
                annotations: ["open_front", "front_trays"],
                animations: ["Scene"],
            },
            {
                id: "2-5",
                text: "Filter door",
                annotations: ["open_side"],
                animations: ["Scene"],
            },
        ],
    },
    {
        id: "3",
        title: "Data analysis",
        hasSublinks: true,
        subLinks: [
            {
                id: "3-1",
                text: "Supporting regulations",
            },
            {
                id: "3-2",
                text: "ImageQuant™ TL​",
            },
        ],
    },
];
