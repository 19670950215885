import {
    grayEmission,
    greenEmission,
    resetCameraAnnotation,
} from "src/constants";
import { useStore } from "../store";
import { Animation, SubLink } from "../types";
import { playHotspot } from "./annotations";
import {
    getMaterial,
    changeMaterialOpacity,
    getMaterialByName,
} from "./materials-textures";

export const storeAnimations = () => {
    const api = useStore.getState().apiref;
    api.getAnimations((err: any, animations: Animation[]) => {
        if (!err) {
            useStore.setState({ animations: animations });
        }
    });
};

export const getAnimation = (index: number) => {
    const { activeSubLink, animations } = useStore.getState();
    return animations.find((animation) => {
        if (activeSubLink.animations) {
            return animation[1] === activeSubLink.animations[index];
        }
    });
};

export const openFrontDoorAndTraysAnimation = () => {
    const { apiref, activeSubLink, setAnimationStopped } = useStore.getState();
    const frontDoorOpenDuration = 2.5;
    const frontDoorAndTraysOpenDuration = 5.35;
    setAnimationStopped(false);
    //Animations
    const sceneAnimation = getAnimation(0);

    // IDs
    const sceneAnimationId = sceneAnimation && sceneAnimation[0];

    activeSubLink.annotations && playHotspot(activeSubLink.annotations[0]);

    // apiref.setCurrentAnimationByUID(sceneAnimationId);
    apiref.pause(() => {
        apiref.seekTo(0, () => {
            apiref.play();
            useStore.setState({ animationIsPlaying: true });
            setTimeout(() => {
                activeSubLink.annotations &&
                    playHotspot(activeSubLink.annotations[1]);
            }, frontDoorOpenDuration * 1000);
            setTimeout(() => {
                apiref.pause(() => {});
                useStore.setState({ animationIsPlaying: false });
            }, frontDoorAndTraysOpenDuration * 1000);
        });
    });
};

export const closeFrontDoorAndTraysAnimation = () => {
    const { apiref, activeSubLink, setAnimationStopped, setActiveSubLink } =
        useStore.getState();
    const openFrontDoorAndTraysDuration = 5.35;
    const fullDurationFrontDoorAndTrays = 9.8;

    activeSubLink.annotations && playHotspot(activeSubLink.annotations[1]);

    apiref.pause(() => {
        apiref.seekTo(openFrontDoorAndTraysDuration, () => {
            apiref.play();
            setTimeout(() => {
                activeSubLink.annotations &&
                    playHotspot(activeSubLink.annotations[0]);
            }, 200);
            setTimeout(() => {
                apiref.pause(() => {});
                playHotspot(resetCameraAnnotation);
                setAnimationStopped(true);
                apiref.seekTo(0);
                setActiveSubLink({} as SubLink);
            }, (fullDurationFrontDoorAndTrays - openFrontDoorAndTraysDuration) * 1000);
        });
    });
};

export const openFilterDoorAndTraysAnimation = () => {
    const { apiref, setAnimationStopped } = useStore.getState();
    const fullDurationFrontDoorAndTrays = 9.8;
    const filterDoorAndTrayOpenDuration = 13.95;
    setAnimationStopped(false);
    //Animations
    const sceneAnimation = getAnimation(0);
    // IDs
    const sceneAnimationId = sceneAnimation && sceneAnimation[0];

    // apiref.setCurrentAnimationByUID(sceneAnimationId);

    apiref.pause(() => {
        apiref.seekTo(fullDurationFrontDoorAndTrays, () => {
            apiref.play();
            useStore.setState({ animationIsPlaying: true });
            setTimeout(() => {
                apiref.pause(() => {});
                useStore.setState({ animationIsPlaying: false });
            }, (filterDoorAndTrayOpenDuration - fullDurationFrontDoorAndTrays) * 1000);
        });
    });
};

export const closeFilterDoorAndTraysAnimation = () => {
    const { apiref, activeSubLink, setAnimationStopped } = useStore.getState();
    const filterDoorAndTrayOpenDuration = 13.95;
    //Animations
    const sceneAnimation = getAnimation(0);

    // Durations
    const sceneAnimationTime = sceneAnimation && sceneAnimation[2];

    activeSubLink.annotations && playHotspot(activeSubLink.annotations[0]);

    apiref.pause(() => {
        apiref.seekTo(filterDoorAndTrayOpenDuration, () => {
            apiref.play();
            setTimeout(() => {
                apiref.pause(() => {});
                playHotspot(resetCameraAnnotation);
                setAnimationStopped(true);
                apiref.seekTo(0);
            }, (sceneAnimationTime - filterDoorAndTrayOpenDuration - 1) * 1000);
        });
    });
};

export const showFootprint = () => {
    const { setAnimationStopped, setFootprintVisible } = useStore.getState();
    setFootprintVisible(true);
    const footprintMat = getMaterial(0);
    if (footprintMat) {
        changeMaterialOpacity(footprintMat, 1);
    }
};

export const hideFootprint = () => {
    const { setAnimationStopped, setFootprintVisible } = useStore.getState();
    const footprintMat = getMaterialByName("FOOTPRINT");
    if (footprintMat) {
        changeMaterialOpacity(footprintMat, 0);
        setFootprintVisible(false);
    }
};

export const toggleInsideBeam = (state: string) => {
    const { setAnimationStopped } = useStore.getState();
    //   setAnimationStopped(false);
    const gr_1_mat = getMaterial(0);
    const gr_2_mat = getMaterial(1);
    const screen_1_mat = getMaterial(2);
    const screen_2_mat = getMaterial(3);
    const usb_hdmi_mat = getMaterial(4);
    const decals_mat = getMaterial(5);
    const labels_mat = getMaterial(6);
    const inside_beam_mat = getMaterial(7);

    const gr_1_opacity = state === "hide" ? 0.01 : 1;
    const gr_2_opacity = state === "hide" ? 0.01 : 1;
    const screen_1_opacity = state === "hide" ? 0.01 : 1;
    const screen_2_opacity = 0;
    const usb_hdmi_opacity = state === "hide" ? 0.01 : 1;
    const decals_opacity = state === "hide" ? 0.01 : 1;
    const labels_opacity = state === "hide" ? 1 : 0;
    const inside_beam_opacity = state === "hide" ? 0.05 : 0;

    if (
        gr_1_mat &&
        gr_2_mat &&
        screen_1_mat &&
        screen_2_mat &&
        usb_hdmi_mat &&
        decals_mat &&
        labels_mat &&
        inside_beam_mat
    ) {
        changeMaterialOpacity(gr_1_mat, gr_1_opacity);
        changeMaterialOpacity(gr_2_mat, gr_2_opacity);
        changeMaterialOpacity(screen_1_mat, screen_1_opacity);
        changeMaterialOpacity(screen_2_mat, screen_2_opacity);
        changeMaterialOpacity(usb_hdmi_mat, usb_hdmi_opacity);
        changeMaterialOpacity(decals_mat, decals_opacity);
        changeMaterialOpacity(labels_mat, labels_opacity);
        changeMaterialOpacity(inside_beam_mat, inside_beam_opacity);
    }
};

export const changeTexture = (material: {
    active: string;
    disactive: string;
}) => {
    const activeTexture = getMaterialByName(material.active);
    const disactiveTexture = getMaterialByName(material.disactive);
    const activeTexture_opacity = 1;
    const disactiveTexture_opacity = 0;

    if (activeTexture && disactiveTexture) {
        changeMaterialOpacity(activeTexture, activeTexture_opacity);
        changeMaterialOpacity(disactiveTexture, disactiveTexture_opacity);
    }
};
