import React, { useEffect } from "react";
import {
  closeFrontDoorAndTraysAnimation,
  openFrontDoorAndTraysAnimation,
} from "src/services/animations";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import { Title, Spacer, Text, IframeVideo } from "../dynamic.components";

export const MultipleTrays: React.FC = (): JSX.Element => {
  const { apiref, sidebarStatus, animations, animationStopped, activeSubLink } =
    useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "open_front"
    ) {
      openFrontDoorAndTraysAnimation();
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "open_front"
    ) {
      closeFrontDoorAndTraysAnimation();
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title="Multiple trays" />
      <div className="c-sidebar__body">
        <Spacer x={2} />
        <IframeVideo src="https://www.youtube.com/embed/E82S0HYkw9Q" />
        <Spacer x={2} />
        <Text
          text="Robust, long-lasting instruments are a must-have for commonly used instruments. 
          ImageQuant™ 800 trays are simple, easy to clean and effortlessly replaced if needed."
        />
      </div>
    </>
  );
};
