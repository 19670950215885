import React from "react";
import { cameraMoveTiming, resetCameraAnnotation } from "src/constants";
import {
    changeTexture,
    hideFootprint,
    openFilterDoorAndTraysAnimation,
    openFrontDoorAndTraysAnimation,
    toggleInsideBeam,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "../../store";
import { SidebarVisibility, SubLink } from "../../types/index.d";
import SidebarContent from "./Sidebar.content.component";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
    const {
        activeSubLink,
        sidebarStatus,
        setSidebarStatus,
        animationIsPlaying,
        setActiveSubLink,
    } = useStore();
    const closeSidebar = () => {
        setSidebarStatus(SidebarVisibility.notShow);
        if (
            activeSubLink.annotations &&
            activeSubLink.annotations[1] &&
            !activeSubLink.animations
        ) {
            playHotspot(activeSubLink.annotations[0]);
            setTimeout(() => {
                playHotspot(resetCameraAnnotation);
            }, cameraMoveTiming * 500);
        } else {
            if (activeSubLink.id === "2-2") {
                hideFootprint();
            }
            if (activeSubLink.id === "1-1") {
                toggleInsideBeam("show");
            }
            if (activeSubLink.id === "1-2") {
                changeTexture({ active: "SCREEN_1", disactive: "SCREEN_2" });
            }
            if (!activeSubLink.animations) {
                playHotspot(resetCameraAnnotation);
            }
            if (activeSubLink.text === "Supporting regulations") {
                setTimeout(() => {
                    setActiveSubLink({} as SubLink);
                }, 500);
            }
        }
    };

    const replayAnimation = () => {
        if (activeSubLink.animations && activeSubLink.animations.length) {
            if (
                activeSubLink.annotations &&
                activeSubLink.annotations[0] === "open_front"
            ) {
                openFrontDoorAndTraysAnimation();
            }
            if (
                activeSubLink.annotations &&
                activeSubLink.annotations[0] === "open_side"
            ) {
                openFilterDoorAndTraysAnimation();
            }
        }
    };

    return (
        <div
            className={`c-sidebar ${
                sidebarStatus === SidebarVisibility.show
                    ? "show-sidebar-motion"
                    : sidebarStatus === SidebarVisibility.notShow &&
                      "hide-sidebar-motion"
            }`}
        >
            <div className="c-sidebar__content">
                <button
                    className="button button--secondary button--xlarge button--thick-border"
                    disabled={animationIsPlaying}
                    onClick={closeSidebar}
                >
                    Return to 3D overview
                </button>
                <SidebarContent />
                {activeSubLink.animations && activeSubLink.animations.length && (
                    <button
                        className={`button button--secondary button--xlarge button--thick-border ${
                            animationIsPlaying ? "button--disabled" : ""
                        }`}
                        onClick={replayAnimation}
                        disabled={animationIsPlaying}
                    >
                        Replay Animation
                    </button>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
