import React, { useEffect } from "react";
import { Title, Text, Subtitle, Image } from "../dynamic.components";
import filter_door_image from "src/assets/images/filter-door/filter-door.png";
import filters_set_up_screen_image from "src/assets/images/filter-door/filters-set-up-screen.png";
import rgb_color_range_image from "src/assets/images/filter-door/rgb-color-range.png";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import {
  closeFilterDoorAndTraysAnimation,
  openFilterDoorAndTraysAnimation,
} from "src/services/animations";

export const FilterDoor: React.FC = (): JSX.Element => {
  const { apiref, sidebarStatus, animations, animationStopped, activeSubLink } =
    useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.id === "2-5"
    ) {
      openFilterDoorAndTraysAnimation();
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.id === "2-5"
    ) {
      closeFilterDoorAndTraysAnimation();
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title="Filter door" />
      <div className="c-sidebar__body">
        <Subtitle subtitle="Separate filter door" />
        <Image
          images={[
            {
              src: filter_door_image,
              alt: "Filter door image",
            },
          ]}
        />
        <Image
          images={[
            {
              src: filters_set_up_screen_image,
              alt: "filters set up screen image",
              customWidth: "70%",
            },
          ]}
        />
        <Image
          images={[
            {
              src: rgb_color_range_image,
              alt: "rgb color range image",
              customWidth: "70%",
            },
          ]}
        />
        <Text
          text="You may add custom filters to accommodate your unique applications or fluorophores. The 7-position filter 
          wheel allows users to add two extra filters without changing the current standard ones, although all are exchangeable."
        />
      </div>
    </>
  );
};
