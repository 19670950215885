import React from "react";
import {
  Title,
  Spacer,
  Text,
  Subtitle,
  Image,
  IframeVideo,
  CustomLink,
  List,
} from "../dynamic.components";
import supporting_regulations_image from "src/assets/images/supporting-regulations/support-reg.png";
import gxp_iqtl_image from "src/assets/images/supporting-regulations/gxp_iqtl.png";

export const SupportingRegulations: React.FC = (): JSX.Element => {
  return (
    <>
      <Title title="Supporting regulations" />
      <div className="c-sidebar__body">
        <Spacer />
        <Text
          text="ImageQuant™ 800 GxP system is an optional module specifically designed for regulated environments to meet the increasing 
          requirements of regulations like FDA 21 CFR part 11 and EU GMP Annex 11 — where data traceability, accountability, and integrity are 
          critical. With features such as Windows® access control, audit logs and image authenticity checks, ImageQuant™ 800 GxP imaging system 
          and ImageQuant™ TL GxP software supports your electronic data management."
        />
        <CustomLink
          href="https://www.cytivalifesciences.com/en/us/shop/protein-analysis/molecular-imaging-for-proteins/imaging-systems/amersham-imagequant-800-gxp-biomolecular-imager-p-25748"
          text="Read all the details here"
        />
        <Spacer x={3} />
        <IframeVideo src="//play.vidyard.com/vLuMvwXwgWv9pwYwd2HB8n.html?" />
        <Spacer x={3} />
        <Subtitle subtitle="Full regulatory support documentation" />

        <Text
          text="Our GxP customers get easy access to all relevant documentation. You can also get a validation support file with an external 
          assessment report of GxP functions for the ImageQuant™ 800 GxP system."
        />
        <Image
          images={[
            {
              src: supporting_regulations_image,
              alt: "supporting regulations image",
              customWidth: "70%",
            },
          ]}
        />
        <Subtitle subtitle="ImageQuant™ TL GxP for regulated environments" />
        <List
          listArray={[
            "Full data integrity and traceability with GxP module for ImageQuant™ TL",
            "Robust password access with Windows based authentication",
            "Audit trails with complete user activity, login attempt, and detailed analysis logs",
            "Client-server approach to data protection for access to secure folders",
            "Electronic signatures for approvers",
            "Emergency login capability",
            "Module with check feature to confirm image integrity before analysis",
          ]}
        />
        <Image
          images={[
            {
              src: gxp_iqtl_image,
              alt: "gxp iqtl",
              customWidth: "70%",
            },
          ]}
        />
      </div>
    </>
  );
};
