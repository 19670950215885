import React from "react";
import { Title, Text, Image, Subtitle } from "../dynamic.components";
import mini_pc_image from "src/assets/images/mini-pc/mini-pc-1.png";

export const MiniPC: React.FC = (): JSX.Element => {
  return (
    <>
      <Title title="Mini PC" />
      <div className="c-sidebar__body">
        <Subtitle subtitle="External mini PC" />
        <Image
          images={[
            {
              src: mini_pc_image,
              alt: "Screen light image",
              customWidth: "65%",
            },
          ]}
        />
        <Text
          text="The ImageQuant™ 800 imaging system comes equipped with touchscreen, two tray positions, and an easy-access filter door. 
          The system combines an intuitive control software along with ImageQuant™ CONNECT software, a connection tool which allows access 
          to the imager from remote locations over the network. A built-in touchscreen and mini external computer result in a small footprint 
          without compromising flexibility. Use the ImageQuant™ CONNECT software to remotely check the status of systems in the facility and 
          reserve imagers for your experiments."
        />
      </div>
    </>
  );
};
