import React from "react";
import {
    Title,
    Image,
    Text,
    List,
    Subtitle,
    Spacer,
    SmallText,
    CustomLink,
} from "../dynamic.components";
import noise_std_image from "src/assets/images/how-snow-works/noise_std_reduction.png";
import seven_half_image from "src/assets/images/how-snow-works/7.5s-SNOW-profile.png";
import fifteen_image from "src/assets/images/how-snow-works/15s-SNOW-profile.png";
import twentytwo_half_image from "src/assets/images/how-snow-works/22.5s-SNOW-profile.png";
import thirtysevent_half_image from "src/assets/images/how-snow-works/37.5s-SNOW-profile.png";
import seventyfive_image from "src/assets/images/how-snow-works/37.5s-SNOW-profile.png";
import fifteen_hundred_image from "src/assets/images/how-snow-works/150-SNOW-profile.png";
import threehundred_sixtyseven_image from "src/assets/images/how-snow-works/367.5s-SNOW-profile.png";
import nine_minute_image from "src/assets/images/how-snow-works/9min-7.5s-SNOW-profile.png";
import auto_sevenhalf_image from "src/assets/images/how-snow-works/auto-7.5.png";
import snow_mode_9_image from "src/assets/images/how-snow-works/snow-mode-9.png";
import Amersham_exposure_8s_image from "src/assets/images/how-snow-works/2/Amersham exposure 8s.png";
import Amersham_exposure_93s_image from "src/assets/images/how-snow-works/2/Amersham exposure 93s.png";
import Long_exposure_image from "src/assets/images/how-snow-works/2/Long exposure.png";

export const HowSnowWorks: React.FC = () => {
    return (
        <>
            <Title title="How SNOW works" />
            <div className="c-sidebar__body">
                <Subtitle subtitle="SNOW (Signal to Noise Optimization Watch) imaging mode" />
                <Text text="Use SNOW automated imaging mode to:" />
                <List
                    listArray={[
                        "detect more weak bands in your Western blot",
                        "achieve high sensitivity without compromising image quality.",
                        "the best quality image is acquired automatically ",
                        "extend the dynamic range by avoiding saturation",
                    ]}
                />
                <Subtitle subtitle="Save time" />
                <Text text="The ImageQuant™ 800 system will automatically stop when the best possible image is acquired, eliminating the need to spend time optimizing exposure and capture settings." />
                <Spacer />
                <CustomLink
                    href="https://www.cytivalifesciences.com/solutions/protein-research/knowledge-center/ccd-image-optimization"
                    text="Read all the details here"
                />
                <Spacer />
                <Subtitle subtitle="Automating signal-to-noise (S/N) optimization for the highest image quality" />
                <Text
                    text="SNOW detection is an innovative acquisition mode which averages multiple exposures to obtain a low-noise 
          image with increased dynamic range that allows accurate quantitation of both low and high abundance signals in the same sample. 
          SNOW detection mode measures an image by assessing one critical parameter: the signal-to-noise ratio."
                />
                <Spacer />
                <Text text="The SNOW imaging mode process involves the following steps:" />
                <List
                    listArray={[
                        "A pre-capture exposure to identify an optimum exposure time",
                        "Selection of the region of interest and background",
                        "Multiple automatic exposures, continuously averaged to find the optimal signal-to-noise ratio",
                    ]}
                />
                <Spacer />
                <Subtitle subtitle="Applies averaging techniques to the auto mode (helps avoid saturation)" />
                <List
                    listArray={[
                        "Takes the same image multiple times in auto mode",
                        "Averages the intensities across the entire image",
                        "Stops automatically when S/N does not improve any more",
                    ]}
                />
                <Spacer />
                <Subtitle subtitle="Minimized random noise" />

                <Text
                    text="The SNOW imaging mode is distinct from other auto-exposure modes in that it works by capturing multiple 
          images and continuously averaging the signal. This image averaging process effectively minimizes random noise, 
          thereby improving the signal-to-noise ratio."
                />
                <Spacer />
                <Subtitle subtitle="The result" />
                <Text
                    text="Clear, crisp, publication-quality, and quantifiable data. A high quality blot image without any 
          user guesswork on exposure times and settings — the most scientific way to image Western blots!"
                />
                <Spacer />
                <Subtitle subtitle="The power of collecting more data to average" />

                <Spacer />
                <Image
                    images={[
                        {
                            src: noise_std_image,
                            alt: "CCD status image",
                        },
                    ]}
                />
                <Image
                    images={[
                        {
                            src: seven_half_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "7.5 s",
                        },
                        {
                            src: fifteen_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "15 s",
                        },
                        {
                            src: twentytwo_half_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "22.5 s",
                        },
                        {
                            src: thirtysevent_half_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "37.5 s",
                        },
                        {
                            src: seventyfive_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "75 s",
                        },
                        {
                            src: fifteen_hundred_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "150 s",
                        },
                        {
                            src: threehundred_sixtyseven_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "367.5 s",
                        },
                        {
                            src: nine_minute_image,
                            alt: "Screen light image",
                            customWidth: "150px",
                            customHeight: "150px",
                            title: "9 m 7.5 s",
                        },
                    ]}
                />
                <Spacer />

                <Image
                    images={[
                        {
                            src: auto_sevenhalf_image,
                            alt: "CCD status image",
                            title: "AUTO 7.5 s",
                        },
                        {
                            src: snow_mode_9_image,
                            alt: "CCD status image",
                            title: "SNOW mode 9 min",
                        },
                    ]}
                />
                <Spacer />
                <Text text="The highest quality gel and blot images without the guesswork using SNOW imaging mode" />
                <Spacer x={2} />
                <SmallText
                    text="A. Amershan ImageQuant 800 (exposure time 8 s)"
                    customClass="text-align-center"
                />
                <Image
                    images={[
                        {
                            src: Amersham_exposure_8s_image,
                            alt: "Amersham exposure 8s image",
                            customWidth: "85%",
                        },
                    ]}
                />
                <Spacer x={2} />
                <SmallText
                    text="B. Amersham ImageQuant 800 exposure SNOW (auto, 93 s)"
                    customClass="text-align-center"
                />
                <Image
                    images={[
                        {
                            src: Amersham_exposure_93s_image,
                            alt: "CCD status image",
                            customWidth: "85%",
                        },
                    ]}
                />
                <Spacer x={2} />
                <SmallText
                    text="C. Long exposure Amershan ImageQuant 800 (exposure time 93 s)"
                    customClass="text-align-center"
                />
                <Image
                    images={[
                        {
                            src: Long_exposure_image,
                            alt: "Long exposure image",
                        },
                    ]}
                />
            </div>
        </>
    );
};
