import React from "react";
import { Title, Image } from "../dynamic.components";

import smallFootprint_image from "src/assets/images/small-footprint/Picture3.png";

export const SmallFootprint: React.FC = (): JSX.Element => {
  return (
    <>
      <Title title="Small footprint" />
      <div className="c-sidebar__body">
        <Image
          images={[
            {
              src: smallFootprint_image,
              alt: "Small Footprint image",
            },
          ]}
        />
      </div>
    </>
  );
};
